import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Elements from './Select.elements';

type Option = {
  value: string;
  label: string;
};

type SelectProps = {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
};
function Select({ value, options, onChange }: SelectProps) {
  return (
    <Elements.SelectWrapper>
      <Elements.SelectInput
        id='chat-type'
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Elements.SelectInput>
      <Elements.ChevronWrapper>
        <FontAwesomeIcon icon={faChevronDown} />
      </Elements.ChevronWrapper>
    </Elements.SelectWrapper>
  );
}

export default Select;
