import { BrowserRouter } from 'react-router-dom';

import Application from './components/Application';
import ReduxProvider from './components/ReduxProvider';

import IntercomWrapper from '~/scenes/Governance/components/IntercomWrapper';

const App = () => (
  <BrowserRouter>
    <ReduxProvider>
      <IntercomWrapper>
        <Application />
      </IntercomWrapper>
    </ReduxProvider>
  </BrowserRouter>
);

export default App;
