/* eslint-disable camelcase */
import { FlowSpec } from '@digibee/flow';
import { ActorRefFrom, assign, createMachine } from 'xstate';

import AIAssistantAPI, {
  AIMessage,
  SendChatMessageParams2
} from '~/api/designAIAssistant';
import i18n from '~/common/helpers/i18n';

export type AIMessageData = {
  input_text?: string;
  output_text?: string;
  flowSpec?: FlowSpec;
  pipe_gen?: {
    flowspec: FlowSpec;
  };
  jolt?: Record<string, unknown>;
};

export type ChatType = 'ai-assistant' | 'pipeline-generator' | 'jolt-assistant';

type Chat = {
  type: ChatType;
  history: AIMessage[];
  hasEnded?: boolean;
};

export type GlobalSidePanelContext = {
  history: Chat[];
  prompt: string;
  joltgenPrompt: {
    input: string;
    output: string;
    invalidInput: boolean;
    invalidOutput: boolean;
  };
  selectedChatType: ChatType;
  detachedChat?: Chat;
};

export type GlobalSidePanelEvents =
  | { type: 'TYPE'; text: string }
  | { type: 'TYPE'; input: string }
  | { type: 'TYPE'; output: string }
  | { type: 'SEND_MESSAGE' }
  | { type: 'SEND_MESSAGE'; text: string }
  | { type: 'SEND_MESSAGE'; input: string; output: string }
  | { type: 'END_CHAT' }
  | { type: 'RETRY' }
  | { type: 'CLEAR' }
  | { type: 'CLEAR_DETACHED_CHAT' }
  | { type: 'CANCEL' }
  | { type: 'SET_CHAT_TYPE'; chatType: ChatType }
  | { type: 'PUSH_DETACHED_CHAT_TO_HISTORY' };

export type GlobalSidePanelServices = {
  sendMessage: {
    data: AIMessage;
  };
};

const getLastChat = (history: Chat[]) => {
  const index = history.length - 1;
  const chat = history[index];
  return [chat, index] as const;
};

const getCurrentChat = (context: GlobalSidePanelContext) => {
  if (context.detachedChat) {
    const index = context.detachedChat.history.length - 1;
    return [context.detachedChat, index] as const;
  }
  return getLastChat(context.history);
};

const sanitizeHistory = (history: AIMessage[]) =>
  history
    .slice(0, history.length - 1)
    .filter(({ off_topic }, index, list) => {
      const nextMessage = list[index + 1];
      if (off_topic || nextMessage?.off_topic) return false;
      return true;
    })
    .map(message => ({ ...message, isOffTopic: undefined }));

const aiChatMachine = () =>
  createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QEECSBhAFgQwC4DoBLCAGzAGIBlAUQDkARAfQFlrLLkBxagbQAYAuolAAHAPaxCuQmIB2wkAA9EAWgBs+AOxqArAEYAzHzUAOPdoAsATh0mANCACeqtRfx6d1gEwWLBqx58Xl4AviEOaFh4RKQU6AAy1MgASvxCSCDiktJyCsoIKl56bsVWJmoGJn5WRZoOzghWmvgGOnwBVXwmXm0mYREYOASwYLIQhLJQ5BByYESyAG5iANZzI2PMcLDYMGkKWVIy8hn5Jnz4PhaaOqYVZT31iK34VhblbTV8BppeTf0gkSG+HW40m5DAACcIWIIfgRCQ8AAzGEAW2Bowgm1g212gn2EkOuROiD8JnwJgpeipXmMxjUVkeCD0X3wfAspisZQM1lemgM-0B0Uh0Ih5GS1AAKskAJp7DIHHLHUD5Aw+fA6KxGTTaKxBamM5l6dXsryaK4BPi2coCwZCqEw8joZC0dDUeJy0QExV5RDM84GfzFSkmVpqXQGnQGclGWx6IpeMNqUL-WRiCBwBSC3D47JHH0FBNedzWcqVaq1RkqDxGq6aEMGYo6HQ+fnhAG2gjEMg5wlKpSqExWF4dQcavzGAKM55mCw03xnKxqdqLm1RYYYiZQHve4kIHo6ck3Gmm16-IwWSueLTs+k9CyeLyDiyroHCmHbvO7gNFkPN3Q0pMgm5A1tAuLwG1sXUkwCbQwjCIA */
      id: 'AIChat',
      tsTypes: {} as import('./AIChat.machine.typegen').Typegen0,
      schema: {
        context: {} as GlobalSidePanelContext,
        events: {} as GlobalSidePanelEvents,
        services: {} as GlobalSidePanelServices
      },
      context: {
        history: [],
        prompt: '',
        selectedChatType: 'pipeline-generator',
        joltgenPrompt: {
          input: '',
          output: '',
          invalidInput: false,
          invalidOutput: false
        }
      },
      initial: 'idle',
      on: {
        TYPE: {
          actions: 'setPrompt'
        },
        CLEAR: {
          actions: 'clearHistory'
        },
        SET_CHAT_TYPE: {
          actions: 'setChatType'
        }
      },
      states: {
        idle: {
          on: {
            SEND_MESSAGE: [
              {
                cond: 'isJoltPromptInvalid'
              },
              {
                cond: 'isDetachedChat',
                target: 'sending',
                actions: ['pushToDetachedChat', 'scrollDown']
              },
              {
                target: 'sending',
                actions: ['pushPromptMessageToHistory', 'scrollDown']
              }
            ],
            CLEAR_DETACHED_CHAT: {
              actions: 'clearDetachedHistory'
            },
            END_CHAT: {
              actions: 'pushDetachedChatToHistory'
            }
          }
        },
        sending: {
          invoke: {
            id: 'sendMessage',
            src: 'sendMessage',
            onDone: [
              {
                cond: 'isDetachedChat',
                target: 'idle',
                actions: ['pushMessageToDetachedChat', 'scrollDown']
              },
              {
                target: 'idle',
                actions: ['pushMessageToHistory', 'scrollDown']
              }
            ],
            onError: 'error'
          }
        },
        error: {
          on: {
            RETRY: 'sending',
            SEND_MESSAGE: [
              {
                cond: 'isDetachedChat',
                target: 'sending',
                actions: ['popLastMessage', 'pushToDetachedChat', 'scrollDown']
              },
              {
                target: 'sending',
                actions: [
                  'popLastMessage',
                  'pushPromptMessageToHistory',
                  'scrollDown'
                ]
              }
            ],
            CANCEL: {
              target: 'idle',
              actions: 'popMessage'
            },
            CLEAR_DETACHED_CHAT: {
              actions: 'clearDetachedHistory'
            }
          }
        }
      }
    },
    {
      guards: {
        isDetachedChat: context =>
          ['pipeline-generator'].includes(context.selectedChatType),
        isJoltPromptInvalid: context => {
          if (context.selectedChatType !== 'jolt-assistant') return false;
          try {
            JSON.parse(context.joltgenPrompt.input);
            JSON.parse(context.joltgenPrompt.output);
            return false;
          } catch {
            return true;
          }
        }
      },
      actions: {
        setChatType: assign({
          selectedChatType: (context, event) => event.chatType
        }),
        setPrompt: assign({
          prompt: (context, event) =>
            'text' in event ? event.text : context.prompt,
          joltgenPrompt: (context, event) => {
            const isInvalidJson = (json: string) => {
              try {
                JSON.parse(json);
                return false;
              } catch {
                return true;
              }
            };

            if ('input' in event) {
              return {
                ...context.joltgenPrompt,
                input: event.input,
                invalidInput: isInvalidJson(event.input)
              };
            }

            if ('output' in event) {
              return {
                ...context.joltgenPrompt,
                output: event.output,
                invalidOutput: isInvalidJson(event.output)
              };
            }

            return context.joltgenPrompt;
          }
        }),
        popLastMessage: assign({
          history: context => {
            if (context.detachedChat) return context.history;
            return context.history.slice(0, -1);
          },
          detachedChat: context => {
            if (!context.detachedChat) return undefined;
            const chat = context.detachedChat;
            return {
              ...chat,
              history: chat.history.slice(0, -1)
            };
          }
        }),
        pushToDetachedChat: assign({
          prompt: '',
          detachedChat: context => {
            if (!context.detachedChat) {
              return {
                type: context.selectedChatType,
                history: [
                  {
                    sender: 'user' as const,
                    text: context.prompt || ''
                  }
                ]
              };
            }

            const chat = context.detachedChat;

            return {
              ...chat,
              history: [
                ...chat.history,
                {
                  sender: 'user' as const,
                  text: context.prompt || ''
                }
              ]
            };
          }
        }),
        pushMessageToDetachedChat: assign({
          detachedChat: (context, event) => {
            const chat = context.detachedChat as Chat; // always defined at this moment

            return {
              ...chat,
              history: [...chat.history, event.data]
            };
          }
        }),
        pushDetachedChatToHistory: assign({
          history: context => {
            const chat = context.detachedChat as Chat; // always defined at this moment

            return [
              ...context.history,
              {
                ...chat,
                hasEnded: true
              }
            ];
          },
          detachedChat: undefined
        }),
        pushPromptMessageToHistory: assign({
          prompt: '',
          joltgenPrompt: {
            input: '',
            output: '',
            invalidInput: false,
            invalidOutput: false
          },
          history: context => {
            const [chat, lastIndex] = getLastChat(context.history);

            if (context.selectedChatType === 'jolt-assistant') {
              return [
                ...context.history,
                {
                  type: context.selectedChatType,
                  history: [
                    {
                      sender: 'user' as const,
                      text: '',
                      data: {
                        input_text: context.joltgenPrompt.input,
                        output_text: context.joltgenPrompt.output
                      }
                    }
                  ]
                }
              ];
            }

            if (lastIndex === -1 || chat.hasEnded) {
              return [
                ...context.history,
                {
                  type: context.selectedChatType,
                  history: [
                    {
                      sender: 'user' as const,
                      text: context.prompt || ''
                    }
                  ]
                }
              ];
            }

            return [
              ...context.history.slice(0, -1),
              {
                ...chat,
                history: [
                  ...chat.history,
                  {
                    sender: 'user' as const,
                    text: context.prompt || ''
                  }
                ]
              }
            ];
          }
        }),
        pushMessageToHistory: assign({
          history: (context, event) => {
            const [chat] = getLastChat(context.history);

            const hasEnded = chat.type === 'jolt-assistant';

            return [
              ...context.history.slice(0, -1),
              {
                ...chat,
                history: [...chat.history, event.data],
                hasEnded
              }
            ];
          }
        }),
        popMessage: assign({
          history: context => context.history.slice(0, -1)
        }),
        clearHistory: assign({
          history: []
        }),
        clearDetachedHistory: assign({
          detachedChat: undefined
        })
      },
      services: {
        sendMessage: async context => {
          const [chat] = getCurrentChat(context);
          const lastMessage = chat.history[chat.history.length - 1];
          const prompt = lastMessage.text;

          const history = sanitizeHistory(chat.history);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { realm } = window.store.getState().application.realm;

          const lang = i18n.language === 'en-US' ? 'en' : 'pt-br';

          const createPayload = (): SendChatMessageParams2 => {
            if (chat.type === 'jolt-assistant') {
              return {
                target: 'jolt-assistant',
                lang,
                realm,
                message: {
                  sender: 'user',
                  text: 'no text',
                  data: {
                    input_text: JSON.parse(
                      lastMessage.data?.input_text || '{}'
                    ),
                    output_text: JSON.parse(
                      lastMessage.data?.output_text || '{}'
                    )
                  }
                }
              };
            }

            return {
              target: chat.type,
              lang,
              realm,
              history: chat.type === 'pipeline-generator' ? history : undefined,
              message: {
                sender: 'user',
                text: prompt
              }
            };
          };

          const payload = createPayload();
          const aiMessage = await AIAssistantAPI.sendAIChatMessage2(payload);

          return {
            ...aiMessage,
            sender: 'ai-assistant'
          };
        }
      }
    }
  );

export type AIChatMachineActor = ActorRefFrom<typeof aiChatMachine>;

export default aiChatMachine;
