import { gql } from '@apollo/client';

import clientApollo from './clientApollo';

const application = {
  find: realm =>
    clientApollo.query({
      query: gql`
        query application($realm: String!) {
          environments(realm: $realm) {
            id
            name
          }
          realms {
            id
            companyName
            name
          }
        }
      `,
      variables: {
        realm
      }
    }),
  getPermissions: ({ realm, environmentCapable }) =>
    clientApollo.query({
      query: gql`
        query permissions($realm: String!, $environmentCapable: Boolean) {
          permissions(realm: $realm, environmentCapable: $environmentCapable)
        }
      `,
      variables: {
        realm,
        environmentCapable
      }
    }),
  getPlatformParameter: ({ realm, name }) =>
    clientApollo.query({
      query: gql`
        query platformParameters($realm: String!, $name: String!) {
          platformParameters(realm: $realm, name: $name)
        }
      `,
      variables: {
        realm,
        name
      }
    }),
  getEnabledSupportAccess: ({ realm }) =>
    clientApollo.query({
      query: gql`
        query enabledSupportAccess($realm: String!) {
          enabledSupportAccess(realm: $realm)
        }
      `,
      variables: {
        realm,
      }
    }),
  updatePlatformParameter: ({ realm, parameter, value, especific = false }) =>
    clientApollo.mutate({
      mutation: gql`
        mutation updatePlatformParameter($realm: String!, $parameter: String!, $value: JSON, $especific: Boolean) {
          updatePlatformParameter(realm: $realm, parameter: $parameter, value: $value, especific: $especific)
        }
      `,
      variables: {
        realm,
        parameter,
        value,
        especific
      }
    }),
  updateEnabledSupportAccess: ({ realm, enabled, userEmail, assignedDays }) =>
    clientApollo.mutate({
      mutation: gql`
        mutation updateEnabledSupportAccess($realm: String!, $enabled: Boolean!, $userEmail: String!, $assignedDays: String!) {
          updateEnabledSupportAccess(realm: $realm, enabled: $enabled, userEmail: $userEmail, assignedDays: $assignedDays)
        }
      `,
      variables: {
        realm,
        enabled,
        userEmail,
        assignedDays
      }
    }),
  getTogaiToken: (realmName) =>
    clientApollo.mutate({
      mutation: gql`
          mutation getTogaiToken($realmName: String!) {
            getTogaiToken(realmName: $realmName)
          }
        `,
      variables: {
        realmName
      }
    }),
};

export default application;
