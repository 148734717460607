import styled from 'styled-components';

type ResizableTextareaProps = {
  withError?: boolean;
};

export const ResizableTextarea = styled.textarea<ResizableTextareaProps>`
  width: 100%;
  min-height: 40px;
  max-height: 300px;
  padding: ${props => props.theme.spacings.byte};
  border: 1px solid ${props => props.theme.colors.gra100};
  border-radius: 4px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  resize: vertical;
  overflow-y: auto;
  box-sizing: border-box;
  outline: none;

  ${props =>
    props.withError ? `border-color: ${props.theme.colors.red300};` : ''}
`;
