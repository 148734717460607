import React, { Component } from 'react';

import * as Elements from './Message.elements';

import i18n from '~/common/helpers/i18n';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class FlowTreeErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Elements.FlowTreeErrorMessage>
          {i18n.t('label.ai_jolt_flow_tree_load_error')}
        </Elements.FlowTreeErrorMessage>
      );
    }

    return children;
  }
}

export default FlowTreeErrorBoundary;
