/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { show, hide, onHide, onShow, onUnreadCountChange, shutdown } from '@intercom/messenger-js-sdk';
import { useState, useEffect } from 'react';

import withHooks from './withHooks';

const withIntercom = withHooks(() => {
  const [toggle, setToggle] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [timeoutFallback, setTimeoutFallback] = useState(null);

  const actions = {
    onToggle: () => {
      setToggle(!toggle);
      return !toggle ? show() : hide();
    }
  };
  useEffect(() => {
    if (!toggle) clearTimeout(timeoutFallback);

    if (toggle) {
      const fallback = setTimeout(() => {
        const intercomElement = document.getElementsByClassName(
          'intercom-messenger-frame'
        );
        if (!intercomElement?.length) {
          window.store.dispatch.intercom.setModal({
            path: 'fallback',
            value: true
          });
          setToggle(false);
        }
      }, 2000);
      setTimeoutFallback(fallback);
    }
  }, [toggle]);

  useEffect(() => {
    onHide(() => {
      setToggle(false);
    });
    onShow(() => {
      setToggle(true);
    });
    onUnreadCountChange(setUnreadCount);
    return () => {
      shutdown();
    };
  }, []);

  return {
    ...actions,
    unreadCount
  };
});
export default withIntercom;
