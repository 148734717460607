import { Button, Dialog, Box, Text } from '@digibee/beehive-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import storage from '~/common/helpers/storage';

const PipelineAccessDenied = () => {
  const { t } = useTranslation();
  const [pipelineAccessDenied, setPipelineAccessDenied] = useState(false);
  const storagePipelineAccessDenied = storage.get('PipelineAccessDenied');
  const handleClick = () => {
    setPipelineAccessDenied(false);
    storage.remove('PipelineAccessDenied');
  };

  useEffect(() => {
    if (storage.get('PipelineAccessDenied')) {
      setPipelineAccessDenied(true);
    }
  }, [storagePipelineAccessDenied]);

  return (
    <Dialog.Root open={!!pipelineAccessDenied}>
      <Dialog.Content data-testid='confirm-dialog-realm-access-denied'>
        <Box
          css={{
            padding: '18px',
            display: 'flex',
            flexDirection: 'column',
            gap: '18px'
          }}
        >
          <Text as='h1' variant='h4'>
            {t('label.you_dont_have_access_pipeline_prompt')}
          </Text>
          <Dialog.Body>
            <Text as='p' variant='b2'>
              {t('label.you_dont_have_access_pipeline_msg')}
            </Text>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              size='medium'
              variant='primary'
              onClick={() => handleClick()}
              data-testid='confirm-dialog-realm-access-denied-confirm-button'
            >
              {t('action.ok')}
            </Button>
          </Dialog.Footer>
        </Box>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default PipelineAccessDenied;
