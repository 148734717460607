import styled from 'styled-components';

// a select with a chevron icon

export const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  font-family: ${props => props.theme.typography.fontFamily};
`;

export const SelectInput = styled.select`
  width: 100%;
  padding: ${props => props.theme.spacings.byte};
  border: 1px solid ${props => props.theme.colors.gra100};
  border-radius: 4px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  appearance: none;
  background-size: 20px;
  outline: none;
`;

export const ChevronWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: ${props => props.theme.spacings.kilo};
  transform: translateY(-50%);
`;
