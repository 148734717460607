import { Button, IconButton } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';
import { faSend, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMachine } from '@xstate/react';
import { useEffect, useRef, useState } from 'react';

import * as Elements from './AIChat.elements';
import aiChatMachine from './AIChat.machine';
import Message from './components/Message/Message';
import { GlobalSidePanelConfig } from '../GlobalSidePanel/GlobalSidePanel';

import i18n from '~/common/helpers/i18n';
import {
  sendToBus,
  useRegisterActorOnEventBus
} from '~/scenes/Build/components/Bus';

type ResizableTextareaProps = {
  value: string;
  onChange: (text: string) => void;
  onResize?: (height: number) => void;
};

function ResizableTextField({
  value,
  onChange,
  onResize
}: ResizableTextareaProps) {
  const [internalValue, setValue] = useState(value);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const resizeTextArea = () => {
    if (!textAreaRef.current) return;
    if (value === '') {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = '40px';
    } else {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
    if (onResize) {
      onResize(value === '' ? 40 : textAreaRef.current.scrollHeight);
    }
  };

  useEffect(resizeTextArea, [onResize, value]);

  return (
    <Elements.ResizableTextarea
      innerRef={(node: HTMLTextAreaElement) => {
        textAreaRef.current = node;
      }}
      value={internalValue}
      onChange={handleChange}
      placeholder={i18n.t('label.ai_assistant_input_placeholder')}
      rows={1}
    />
  );
}

function AIChat() {
  const [textFieldHeight, setTextFieldHeight] = useState(40);
  const chatContentRef = useRef<HTMLDivElement | null>(null);
  const [state, send, actor] = useMachine(aiChatMachine, {
    actions: {
      scrollDown: () => {
        setTimeout(() => {
          chatContentRef.current?.scrollTo({
            top: chatContentRef.current.scrollHeight,
            behavior: 'instant'
          });
        }, 100);
      }
    }
  });

  useRegisterActorOnEventBus(actor);

  const handlePromptTextChange = (text: string) => {
    send({ type: 'TYPE', text });
  };

  const handleSendMessage = () => {
    send({ type: 'SEND_MESSAGE' });
  };

  const handleRetry = () => {
    send({ type: 'RETRY' });
  };

  return (
    <Elements.ChatWrapper>
      <Elements.ChatContent
        textFieldHeight={textFieldHeight}
        innerRef={(node: HTMLDivElement) => {
          chatContentRef.current = node;
        }}
      >
        <Elements.DocumentationWrapper>
          <Elements.Title>
            {i18n.t('label.ai_assistant_how_to_use_title')}
          </Elements.Title>
          <Elements.Description>
            {i18n.t('label.ai_assistant_how_to_use_text')}
            <br />
            {i18n.t('label.ai_assistant_how_to_use_examples_title')}
          </Elements.Description>
          <Message
            noImage
            text={i18n.t('label.ai_assistant_how_to_use_example_1')}
          />
          <Message
            noImage
            text={i18n.t('label.ai_assistant_how_to_use_example_2')}
          />
        </Elements.DocumentationWrapper>
        <Elements.Divider />
        <Elements.MessagesWrapper>
          {state.context.history.map(message => (
            <Message
              text={message.text}
              sender={message.sender}
              flowSpec={message.sender === 'ai' ? message.flowSpec : undefined}
            />
          ))}
          {iff(state.matches('sending'), () => (
            <Message loading />
          ))}
          {iff(state.matches('error'), () => (
            <Message isError onRetry={handleRetry} />
          ))}
        </Elements.MessagesWrapper>
      </Elements.ChatContent>
      <Elements.ChatFieldWrapper>
        <ResizableTextField
          value={state.context.prompt}
          onChange={handlePromptTextChange}
          onResize={setTextFieldHeight}
        />
        <Elements.ChatActionWrapper>
          <Button size='medium' onClick={handleSendMessage}>
            <FontAwesomeIcon icon={faSend} />
          </Button>
        </Elements.ChatActionWrapper>
      </Elements.ChatFieldWrapper>
    </Elements.ChatWrapper>
  );
}

const AIChatActions = () => (
  <IconButton
    data-testid='global-panel-close-button'
    onClick={() => sendToBus({ to: 'AIChat', event: { type: 'CLEAR' } })}
    css={{
      width: 32,
      height: 32
    }}
  >
    <FontAwesomeIcon size='lg' icon={faTrash} />
  </IconButton>
);

const aiChatPanelConfig: GlobalSidePanelConfig = {
  name: 'AIChat',
  title: i18n.t('label.ai_assistant'),
  component: <AIChat />,
  actionsSlot: <AIChatActions />,
  isBeta: true
};

export default aiChatPanelConfig;
