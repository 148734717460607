/* eslint-disable camelcase */
import { isApolloError, gql } from '@apollo/client';
import { FlowSpec } from '@digibee/flow';

import clientApollo from './clientApollo';

import { AIMessageData } from '~/scenes/AI/components/AIChat2/AIChat.machine';

type GetNextComponentParams = {
  triggerName?: string;
  sequence?: string[];
};

type NextComponentSuggestion = {
  class: string;
  probability: number;
};
type GetNextComponentResponse = {
  aiNextComponentSuggestions: NextComponentSuggestion[];
};

type SendChatMessageParams = {
  prompt: string;
  history: {
    text: string;
    sender: string;
    flowSpec?: FlowSpec;
  }[];
};

export type AIMessage = {
  actions?: Record<string, unknown>[];
  triggers?: Record<string, unknown>[];
  data?: AIMessageData;
  sender: string;
  text: string;
  off_topic?: boolean;
};

export type SendChatMessageParams2 = {
  realm: string;
  target: string;
  lang: string;
  history?: AIMessage[];
  message: AIMessage;
};

type SendChatMessageResponse = {
  aiSendChatMessage: {
    text: string;
    flowSpec: FlowSpec;
    isOffTopic: boolean;
  };
};
type SendChatMessageResponse2 = {
  aiSendChatMessage2: AIMessage;
};

const designAIAssistant = {
  sendAIChatMessage: async ({ prompt, history }: SendChatMessageParams) => {
    try {
      const result = await clientApollo.mutate<SendChatMessageResponse>({
        mutation: gql`
          mutation aiSendChatMessage(
            $prompt: String!
            $history: [AIPipelineChatMessage]!
          ) {
            aiSendChatMessage(prompt: $prompt, history: $history)
          }
        `,
        variables: {
          prompt,
          history
        }
      });

      if (!result.data) throw Error('No data returned from server');

      return result.data.aiSendChatMessage;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },
  sendAIChatMessage2: async ({
    lang,
    message,
    realm,
    target,
    history
  }: SendChatMessageParams2) => {
    try {
      const result = await clientApollo.mutate<SendChatMessageResponse2>({
        mutation: gql`
          mutation aiSendChatMessage2($payload: AIAssistantPayload!) {
            aiSendChatMessage2(payload: $payload)
          }
        `,
        variables: {
          payload: {
            lang,
            message,
            realm,
            target,
            history
          }
        }
      });

      if (!result.data) throw Error('No data returned from server');

      return result.data.aiSendChatMessage2;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  },

  getNextComponentSuggestions: async ({
    triggerName,
    sequence
  }: GetNextComponentParams) => {
    try {
      const result = await clientApollo.query<GetNextComponentResponse>({
        query: gql`
          query aiNextComponentSuggestions(
            $triggerName: String
            $sequence: [String]
          ) {
            aiNextComponentSuggestions(
              triggerName: $triggerName
              sequence: $sequence
            ) {
              class
              probability
            }
          }
        `,
        variables: {
          triggerName,
          sequence
        }
      });

      return result.data.aiNextComponentSuggestions;
    } catch (e) {
      if (e instanceof Error && isApolloError(e)) throw Error(e.message);
      throw e;
    }
  }
};

export default designAIAssistant;
