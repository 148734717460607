import styled from 'styled-components';

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const UserImageWrapper = styled.div`
  align-self: start;
  margin-right: 10px;
`;

export const AIImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.gra100};
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
`;

export const AIImage = styled.img`
  width: 30px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacings.byte};
  flex: 1;
  width: 325px;
`;

export const MessageContent = styled.div`
  flex: 1;
  background-color: ${props => props.theme.colors.gra25};
  color: ${props => props.theme.colors.gra600};
  border-radius: 4px;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.gra100};
  font-family: ${props => props.theme.typography.fontFamily};
`;

export const FlowTreeContainer = styled.div`
  height: 400px;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  margin-bottom: ${props => props.theme.spacings.kilo};
  border-radius: 4px;
`;

export const FlowTreeErrorMessage = styled.div`
  height: 100px;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  margin-bottom: ${props => props.theme.spacings.kilo};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// action there are "floating" buttons that are not part of the main content
export const MessageActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${props => props.theme.spacings.byte};
`;

export const Link = styled.a`
  color: ${props => props.theme.colors.pri500};
  text-decoration: none;
  cursor: pointer;
`;

export const ActionIconWrapper = styled.div`
  margin-right: ${props => props.theme.spacings.byte};
`;

export const MarkdownWrapper = styled.div`
  font-family: ${props => props.theme.typography.fontFamily};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5em 0 0.5em;
    font-weight: bold;
  }

  h1 {
    font-size: 2em;
    border-bottom: 2px solid #ccc;
    padding-bottom: 0.3em;
  }

  h2 {
    font-size: 1.75em;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.3em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.25em;
  }

  h5 {
    font-size: 1em;
  }

  h6 {
    font-size: 0.875em;
    color: #666;
  }

  /* Estilos para listas */
  ul,
  ol {
    margin: 1em 0;
    padding-left: 1.5em;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  li {
    margin: 0.5em 0;
  }

  /* Estilos para blocos de código */
  pre {
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 4px;
    overflow: auto;
  }

  code {
    background-color: #f5f5f5;
    padding: 0.2em 0.4em;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
  }

  /* Estilos para blocos de citação */
  blockquote {
    margin: 1em 0;
    padding: 0.5em 1em;
    border-left: 4px solid #ccc;
    background-color: #f9f9f9;
    color: #666;
  }

  /* Estilos para links */
  a {
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  /* Estilos para imagens */
  img {
    max-width: 100%;
    height: auto;
  }

  /* Estilos para parágrafos */
  p {
    margin: 1em 0;
    line-height: 1.6;
  }
`;

export const Pre = styled.pre`
  padding: ${props => props.theme.spacings.kilo};
  border-radius: 8px;
  background: ${props => props.theme.colors.gra50};
  font-size: 14px;
  overflow: auto;
  width: 302px;
  margin-bottom: ${props => props.theme.spacings.byte};
  box-sizing: border-box;
`;

export const InnerLabel = styled.div`
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacings.byte};
`;
