import { useEffect, useRef, useState } from 'react';

import * as Elements from './ResizableTextField.elements';

type ResizableTextareaProps = {
  value: string;
  onChange: (text: string) => void;
  onResize?: (height: number) => void;
  rows?: number;
  maxRows?: number;
  placeholder?: string;
  withError?: boolean;
};

const ROW_HEIGHT = 40;

function ResizableTextField({
  value,
  onChange,
  onResize,
  placeholder,
  withError,
  maxRows = 3,
  rows = 1
}: ResizableTextareaProps) {
  const [internalValue, setValue] = useState(value);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const resizeTextArea = () => {
    if (!textAreaRef.current) return;
    if (value === '' || textAreaRef.current.scrollHeight < ROW_HEIGHT * rows) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${ROW_HEIGHT * rows}px`;
    } else if (textAreaRef.current.scrollHeight < ROW_HEIGHT * maxRows) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
    if (onResize) {
      onResize(
        value === '' ? ROW_HEIGHT * rows : textAreaRef.current.scrollHeight
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resizeTextArea, [onResize, value]);

  return (
    <Elements.ResizableTextarea
      innerRef={(node: HTMLTextAreaElement) => {
        textAreaRef.current = node;
      }}
      value={internalValue}
      onChange={handleChange}
      placeholder={placeholder}
      rows={rows}
      withError={withError}
    />
  );
}

export default ResizableTextField;
