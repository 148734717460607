import { Avatar, Button } from '@digibee/beehive-ui';
import { choose, iff, otherwise, when } from '@digibee/control-statements';
import Flow, { Component, FlowSpec } from '@digibee/flow';
import { faCopy, faPaste } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash/fp';
import Markdown from 'markdown-to-jsx';
import { useCallback, useMemo } from 'react';
import { useSelector as useReduxSelector } from 'react-redux';

import FlowTreeErrorBoundary from './FlowTreeErrorBoundary';
import * as Elements from './Message.elements';
import { ChatType, AIMessageData } from '../../AIChat.machine';
import LoadingBar from '../LoadingBar/LoadingBar';

import { API_KEY } from '~/api/variables';
import i18n from '~/common/helpers/i18n';
import applicationTheme from '~/common/styled/theme';
import { sendToBus } from '~/scenes/Build/components/Bus';
import FlowTree from '~/scenes/Build/components/Design/panels/Tree/components/FlowTree';
import { ShapeType } from '~/scenes/Build/components/Design/panels/Tree/components/FlowTree/Tree';
import { Connectors, getConnector } from '~/scenes/Build/helpers';
import { StoreRootState } from '~/scenes/Governance/types/StoreRootState';

const useMapConnectorImage = () => {
  const connectors = useReduxSelector(
    (state: StoreRootState) => state.designPipeline.connectors as Connectors
  );

  return useCallback(
    (
      item: Component | string
    ): {
      shape: ShapeType;
      image: string;
    } => {
      const nonExistentConnector = getConnector(connectors, 'non-existent');

      if (!item)
        return {
          shape: nonExistentConnector.shape as ShapeType,
          image: nonExistentConnector.image
        };

      if (typeof item === 'string') {
        const trigger = getConnector(connectors, 'trigger', item);

        return {
          shape: trigger.shape as ShapeType,
          image: trigger.image
        };
      }

      const itemSpec = item?.spec?.();
      const connector = getConnector(
        connectors,
        itemSpec?.type,
        itemSpec?.name
      );

      if (connector) {
        return {
          shape: connector.shape as ShapeType,
          image: connector.image
        };
      }

      return {
        shape: nonExistentConnector.shape as ShapeType,
        image: nonExistentConnector.image
      };
    },
    [connectors]
  );
};

const getStartDisconnectedTrackName = (flowSpec: FlowSpec): string => {
  const trackName = Object.keys(flowSpec).find(key =>
    key.startsWith('disconnected-root')
  );
  return trackName || 'start';
};

const fixFlowSpecStartTrack = (flowSpec: FlowSpec): FlowSpec => {
  const clonnedFlowspec = cloneDeep(flowSpec) as FlowSpec;

  const trackName = getStartDisconnectedTrackName(clonnedFlowspec);
  clonnedFlowspec.start = clonnedFlowspec[trackName];

  delete clonnedFlowspec[trackName];

  return clonnedFlowspec;
};

type MessageProps = {
  text?: string;
  sender?: string;
  image?: string;
  loading?: boolean;
  isError?: boolean;
  onRetry?: () => void;
  noImage?: boolean;
  chatType?: ChatType;
  data?: AIMessageData;
};

function Message({
  sender,
  text,
  loading,
  isError,
  noImage,
  chatType,
  data,
  onRetry
}: MessageProps) {
  const flowSpec = data?.pipe_gen?.flowspec;

  const profile = useReduxSelector((state: StoreRootState) => state.profile);

  const token = useReduxSelector(
    (state: StoreRootState) => state.authentication.userData?.token
  );

  const flow = useMemo(
    () => new Flow(flowSpec ? fixFlowSpecStartTrack(flowSpec) : {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const mapConnectorImage = useMapConnectorImage();

  const insertOnCanvasAction = () => {
    sendToBus({
      event: {
        type: 'PASTE',
        value: JSON.stringify({ flowSpec, meta: {} })
      },
      to: 'design'
    });
  };

  const copyFlowspecActions = () => {
    navigator.clipboard.writeText(JSON.stringify({ flowSpec, meta: {} }));
  };

  const copyJoltSpec = () => {
    navigator.clipboard.writeText(JSON.stringify(data?.jolt, null, 2));
  };

  return (
    <Elements.MessageContainer>
      {iff(!noImage, () => (
        <Elements.UserImageWrapper>
          {choose(
            when(sender === 'user', () => (
              <Avatar
                data-testid='profile-show-menu-button'
                size='small'
                text={profile.user?.email}
                placeholder={profile.user?.email}
                type={profile.user?.imageUrl ? 'picture' : 'char'}
                config={{
                  provider: {
                    url: `${profile.user?.imageUrl}?${profile.avatarTimestamp}`,
                    options: {
                      responseType: 'blob',
                      headers: {
                        apiKey: API_KEY,
                        Authorization: token
                      }
                    }
                  }
                }}
              />
            )),
            otherwise(() => (
              <Elements.AIImageContainer>
                <Elements.AIImage
                  src={applicationTheme.images.iconLogoDigibee}
                  alt='AI'
                />
              </Elements.AIImageContainer>
            ))
          )}
        </Elements.UserImageWrapper>
      ))}
      <Elements.MessageWrapper>
        <Elements.MessageContent>
          {choose(
            when(!!isError, () => (
              <>
                An error occured when we tried to process your request.
                <Elements.Link onClick={onRetry}> Try again</Elements.Link>
              </>
            )),
            when(!!loading, () => <LoadingBar />),
            otherwise(() => (
              <>
                {sender === 'ai-assistant' &&
                  chatType === 'pipeline-generator' && (
                    <FlowTreeErrorBoundary>
                      <Elements.FlowTreeContainer>
                        <FlowTree
                          flow={flow}
                          currentLevelName='start'
                          mapConnectorImage={mapConnectorImage}
                          withoutStarterNode
                        />
                      </Elements.FlowTreeContainer>
                    </FlowTreeErrorBoundary>
                  )}

                {sender === 'user' &&
                  chatType === 'jolt-assistant' &&
                  data !== undefined && (
                    <>
                      <Elements.InnerLabel>
                        {i18n.t('label.ai_jolt_generator_input')}:
                      </Elements.InnerLabel>
                      <Elements.Pre>{data.input_text}</Elements.Pre>
                      <Elements.InnerLabel>
                        {' '}
                        {i18n.t('label.ai_jolt_generator_output')}:
                      </Elements.InnerLabel>
                      <Elements.Pre>{data.output_text}</Elements.Pre>
                    </>
                  )}

                {sender === 'ai-assistant' &&
                  chatType === 'jolt-assistant' &&
                  data?.jolt && (
                    <>
                      <Elements.InnerLabel>
                        {i18n.t('label.ai_jolt_generator_jolt_spec')}:
                      </Elements.InnerLabel>
                      <Elements.Pre>
                        {JSON.stringify(data?.jolt, null, 2)}
                      </Elements.Pre>
                    </>
                  )}
                <Elements.MarkdownWrapper>
                  <Markdown>{text || ''}</Markdown>
                </Elements.MarkdownWrapper>
              </>
            ))
          )}
        </Elements.MessageContent>
        {sender === 'ai-assistant' && chatType === 'pipeline-generator' && (
          <Elements.MessageActions>
            <Button outlined size='small' onClick={copyFlowspecActions}>
              <Elements.ActionIconWrapper>
                <FontAwesomeIcon icon={faCopy} />
              </Elements.ActionIconWrapper>
              {i18n.t('label.ai_assistant_action_copy')}
            </Button>
            <Button outlined size='small' onClick={insertOnCanvasAction}>
              <Elements.ActionIconWrapper>
                <FontAwesomeIcon icon={faPaste} />
              </Elements.ActionIconWrapper>
              {i18n.t('label.ai_assistant_action_insert')}
            </Button>
          </Elements.MessageActions>
        )}

        {sender === 'ai-assistant' && chatType === 'jolt-assistant' && (
          <Elements.MessageActions>
            <Button outlined size='small' onClick={copyJoltSpec}>
              <Elements.ActionIconWrapper>
                <FontAwesomeIcon icon={faCopy} />
              </Elements.ActionIconWrapper>
              {i18n.t('label.ai_assistant_action_copy')}
            </Button>
          </Elements.MessageActions>
        )}
      </Elements.MessageWrapper>
    </Elements.MessageContainer>
  );
}

export default Message;
