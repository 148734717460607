import { useSelector } from 'react-redux';

import { StoreRootState } from '../../types/StoreRootState';

import { API_KEY_INTERCOM } from '~/common/helpers/environment';
import withHooks from '~/common/hoc/withHooks';

export type Props = {
  userId: string,
  email: string,
  userHash: string,
  name: string,
  realm: string,
  appID: string,
}

const enchancer = withHooks(() => {
  const { authentication, intercom, profile } = useSelector((state: StoreRootState) => state);

  const props: Props = {
    appID: API_KEY_INTERCOM,
    userId: authentication.userData ? authentication.userData.email : '',
    email: authentication.userData ? authentication?.userData?.email : '',
    userHash: intercom.key || '',
    name: profile.user
      ? `${profile?.user?.firstName} ${profile?.user?.lastName}`
      : '',
    realm: authentication.userData ? authentication.userData.realm : ''
  };

  return props;
});

export default enchancer;
