import Intercom, { boot } from '@intercom/messenger-js-sdk';

import { Props } from './IntercomWrapper.enchancer';


const IntercomWrapper = ({
  email,
  name,
  userId,
  userHash,
  appID,
  children
}: React.PropsWithChildren<Props>) => {
  Intercom({
    app_id: appID,
  });

  if (name) {
    boot({
      name,
      email,
      user_id: userId,
      user_hash: userHash,
      app_id: appID,
    });
  }

  return children;
}

export default IntercomWrapper;
