import styled from 'styled-components';

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  font-family: ${props => props.theme.typography.fontFamily};
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 57px;
  left: 0;
  width: 100%;
  height: calc(100vh - 122px);
  background: rgba(255, 255, 255, 0.5);
  z-index: 1000;
`;

export const ChatContent = styled.div`
  position: relative;
  overflow-y: auto;
  padding: ${props => props.theme.spacings.mega} 0;
  box-sizing: border-box;
  flex: 1;
`;

export const ChatActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${props => props.theme.spacings.kilo};
  box-sizing: border-box;
  align-items: end;
`;

export const ChatSendButton = styled.button`
  padding: ${props => props.theme.spacings.kilo};
  border: 1px solid ${props => props.theme.colors.gra100};
  border-radius: 8px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  cursor: pointer;
`;

export const ChatFieldWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  width: 100%;
  padding: ${props => props.theme.spacings.kilo};
  border-top: 1px solid ${props => props.theme.colors.gra100};
`;

export const ResizableTextarea = styled.textarea`
  width: 100%;
  min-height: 40px;
  max-height: 300px;
  padding: ${props => props.theme.spacings.byte};
  border: 1px solid ${props => props.theme.colors.gra100};
  border-radius: 4px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  resize: vertical;
  overflow-y: auto;
  box-sizing: border-box;
  outline: none;
`;

export const ChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacings.kilo};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.gra100};
  margin: ${props => props.theme.spacings.kilo} 0;
`;

export const DocumentationWrapper = styled.div`
  padding: 0 ${props => props.theme.spacings.kilo};
`;

export const Title = styled.h1`
  font-size: 18px;
  margin-bottom: ${props => props.theme.spacings.mega};
  font-weight: 500;
`;

export const Description = styled.p`
  font-size: 16px;
  margin-bottom: ${props => props.theme.spacings.kilo};
`;

export const ConversationWrapper = styled.div`
  padding: 0 ${props => props.theme.spacings.kilo};
`;

export const MessagesWrapper = styled.div`
  & ${ConversationWrapper}:nth-child(even) {
    background-color: #f7f7f7;
    padding-top: ${props => props.theme.spacings.kilo};
    padding-bottom: ${props => props.theme.spacings.kilo};
    margin-bottom: ${props => props.theme.spacings.kilo};
  }
`;

type DetachedChatProps = {
  offset: number;
};

export const DetachedChat = styled.div<DetachedChatProps>`
  position: fixed;
  bottom: calc(1px + ${props => props.offset}px);
  min-height: 200px;
  background: #f9f9f9;
  border-top: 1px solid ${props => props.theme.colors.gra100};
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
`;

export const DetachedChatHeader = styled.div`
  padding: ${props => props.theme.spacings.kilo};
  font-family: ${props => props.theme.typography.fontFamily};
  color: ${props => props.theme.colors.gra600};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.gra100};
  padding-bottom: ${props => props.theme.spacings.kilo};
  height: 32px;
  align-items: center;
`;

export const DetachedChatTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  display: flex;
  gap: ${props => props.theme.spacings.byte};
`;

export const DetachedChatActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: ${props => props.theme.spacings.byte};
`;

export const DetachedChatContent = styled.div`
  padding: ${props => props.theme.spacings.kilo};
  overflow-y: auto;
  max-height: calc(100vh - 420px);
`;

export const DetachedFinishAction = styled.div`
  height: 100%;
  padding-left: ${props => props.theme.spacings.byte};
  border-left: 1px solid ${props => props.theme.colors.gra100};
`;

export const PromptFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
